import { Link } from "components/Link";
import { WHITE } from "shared/variables";
import cx from "classnames";
import styles from "./PageTitleWithLabel.module.css";

export const PageTitleWithLabel = ({ label, title, color, link }) => (
  <div>
    {label && link ? (
      <Link
        field={link}
        className={cx(styles.label, { [styles.isWhite]: color === WHITE })}
      >
        {label}
      </Link>
    ) : (
      <div className={cx(styles.label, { [styles.isWhite]: color === WHITE })}>
        {label}
      </div>
    )}
    {title && (
      <h1 className={cx(styles.title, { [styles.isWhite]: color === WHITE })}>
        {title}
      </h1>
    )}
  </div>
);
