import { Button } from "components/Button";
import { CYAN, EXTRALARGE, GREEN, WHITE, SMALL } from "shared/variables";
import { MaxWidth } from "components/MaxWidth";
import { Page } from "components/Page";
import { PageTitleWithLabel } from "components/PageTitleWithLabel";
import { RichText } from "components/slices/RichText";
import { SpacingSection } from "components/SpacingSection";
import React from "react";

export async function getStaticProps() {
  return { props: {} };
}

const page = ({ preview }) => (
  <Page
    title="Oh, oh, een 404."
    description="De pagina die je zocht kon niet worden gevonden."
    color={CYAN}
    preview={preview}
  >
    <SpacingSection size={EXTRALARGE}>
      <MaxWidth>
        <PageTitleWithLabel
          color={WHITE}
          label="Oh, oh, een 404."
          title="De pagina die je zocht kon niet worden gevonden."
        />
        <SpacingSection size={SMALL}>
          <RichText color={WHITE}>
            <p>
              Helaas konden we de pagina die je zocht niet vinden. We hopen dat
              je vanaf de homepage de weg weer kunt vinden!
            </p>
          </RichText>
        </SpacingSection>
        <SpacingSection size={SMALL}>
          <Button color={GREEN} link={{ type: "homepage" }}>
            Naar de homepage
          </Button>
        </SpacingSection>
      </MaxWidth>
    </SpacingSection>
  </Page>
);

export default page;
